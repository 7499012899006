<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit" enctype="multipart/form-data">
      <v-container>
        <v-row>
          <v-col>
            <!-- Name -->
            <validation-provider
              v-slot="{ errors }"
              name="Name"
              rules="required"
            >
              <v-text-field
                dense
                outlined
                v-model="formData.first_name"
                :error-messages="errors"
                label="Name"
                required
              ></v-text-field>
            </validation-provider>
            <!-- Last Name -->
            <validation-provider
              v-slot="{ errors }"
              name="Last Name"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                v-model="formData.last_name"
                :error-messages="errors"
                label="Last Name"
                required
              ></v-text-field>
            </validation-provider>
            <!-- Second Last Name -->
            <validation-provider
              v-slot="{ errors }"
              name="Second Last Name"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.second_last_name"
                v-model="formData.second_last_name"
                :error-messages="errors"
                label="Second Last Name"
                required
              ></v-text-field>
            </validation-provider>
            <!-- EMAIL -->
            <validation-provider
              v-slot="{ errors }"
              name="Email"
              :rules="{
                required: true,
                email: true,
              }"
            >
              <v-text-field
                dense
                outlined
                v-model="formData.email"
                :error-messages="errors"
                label="Email"
                required
              ></v-text-field>
            </validation-provider>
            <!-- PHONE -->
            <validation-provider
              v-slot="{ errors }"
              name="Phone Number"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="formData.phone_number"
                :error-messages="errors"
                label="Phone Number"
                required
              ></v-text-field>
            </validation-provider>
            <!-- POSITION -->
            <validation-provider
              v-slot="{ errors }"
              name="Position"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="formData.position"
                :error-messages="errors"
                label="Position"
                required
              ></v-text-field>
            </validation-provider>
            <!-- Password -->
            <validation-provider
              v-slot="{ errors }"
              name="Password"
              :rules="{
                required: tenantUserData.id? false : true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="formData.password"
                :error-messages="errors"
                label="Password"
                required
                :type="passFlag ? 'text' : 'password'"
                @click:append="passFlag = !passFlag"
                :append-icon="passFlag ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="clear" text> reset </v-btn>
          <v-btn
            @click="submit"
            color="success"
            text
            type="button"
            :disabled="invalid"
            :loading.sync="loadings.formBtn"
          >
            {{confirmBtnText}}
          </v-btn>
        </v-card-actions>
    </form>
  </validation-observer>
</template>

<script>
import { required, digits, max, regex, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapActions, mapGetters } from "vuex";

setInteractionMode("eager");

extend("email", {
  ...email,
  message: "Email must be valid",
});

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} not is valid",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      passFlag: false,
      dialog: false,
      uploadDialog: true,
      loadings: {
        formBtn: false,
      },
      tenantUserData: {},
      formData: {
        first_name: "",
        last_name: "",
        second_last_name: "",
        email: "",
        phone_number: "",
        position: "",
        password: "",
        account_type: 'admin',
      },
      vue_app_wms_id: process.env.VUE_APP_WMS_ID,
      vue_app_inv_id: process.env.VUE_APP_INVENTORY_ID
    };
  },
  mounted() {
    // this.wmsRoleOptions =
    if(this.type == 'supplier'){
      this.mktRoleOptions = ['supplier']
    } else{
      this.mktRoleOptions = ['buyer']
    }
  },
  computed: {
    ...mapGetters([""]),
    confirmBtnText() {
      const self = this;
      let title = "";
      if (self.tenantUserData.id) {
        title = `Update`;
      } else {
        title = `Create`;
      }
      return title;
    },
  },
  methods: {
    ...mapActions(["createAdminUser"]),
    /* CREATE USER */
    async submit() {

      const self = this;
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      
      const userData = {
        ...self.formData,
        username: self.formData.email,
      }

      self.formBtn = true;

      self
      .createAdminUser(userData)
      .then((suss) => {
        self.showNotification({
          type: 1,
          text: suss.message,
          lngKey: false,
        });
        self.$nextTick(() => {
          self.clear();
          self.$router.push({ path: `/admin/list` })
          self.dialog = false;
        });
      })
      .catch((err) => {
        self.$swal.fire({
          timer: 4000,
          icon: "error",
          title: err.statusText,
          text: err.data.message || "Error trying to store admin user",
        });
      })
      .finally(() => {
        self.formBtn = false;
      });
      
    },
    clear() {
      const self = this;
      self.$refs.observer.reset();
      self.formData = {
        first_name: "",
        last_name: "",
        second_last_name: "",
        email: "",
        phone_number: "",
        position: "",
        password: "",
      };
    },
  },
};
</script>